<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://www.collab2.co.za/wp-content/uploads/2017/06/contact-us-background.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a
                class="title"
                title="Contact Us
"
                >Contact Us
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container acs-worldwide">
      <div class="row">
        <div class="col-md-10 offset-md-1 ">
          <div
            class="page-content"
            itemscope
            itemtype="http://schema.org/WebPage"
          >
            <div class="row">
              <div class="col-md-6">
                <h5>TBS Solutions Sdn Bhd</h5>
                <p>
                  20B, Jalan Todak 5, <br />
                  Pusat Bandar Seberang Jaya, <br />
                  13700 Seberang Jaya, <br />
                  Pulau Pinang. <br />
                </p>
                <p>
                  Tel: 604-399 8789
                  <br />
                  Fax: 604-397 7728
                  <br />
                  Email:
                  <b-link href="mailto: enquiry@tbs.com.my"
                    >enquiry@tbs.com.my</b-link
                  >
                </p>
                <p>
                  Opening Times
                  <br />
                  Monday - Friday 8.30 AM - 6.00 PM
                </p>
              </div>
              <div class="col-md-6">
                <!-- <iframe
                  width="100%"
                  height="600"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Graft20B,%20Jln.%20Todak%205,%20Pusat%20Bdr.%20Seberang%20Jaya,%2013700%20Seberang%20Jaya,%20P.%20Pinang,%2013700on%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe> -->
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      width="693"
                      height="500"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=TBS%20Solutions%20Sdn%20Bhd&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs"
};
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}
</style>
